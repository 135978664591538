import PropTypes from 'prop-types'
import React from "react"
import './MailingListSignup.scss'
import MailingListSignupForm from "../MailingListSignupForm/MailingListSignupForm";


class MailingListSignup extends React.Component {
  static propTypes = {
    signupLocation: PropTypes.string.isRequired,
  }
  render() {
    return (
      <div className="mailing-list section grey">
        <div className="container px-4 px-sm-0">
          <div className="row">
            <div className="col-md-6 offset-md-3">
              <h2>Join our mailing list!</h2>
              <p>We'll send you an occasional email to keep you up to date with what we're working on and to share helpful links and resources that will help you become a better freelancer.</p>
              <MailingListSignupForm signupLocation={this.props.signupLocation}/>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default MailingListSignup

