import "../../scss/pages/Features.scss"
import { Link } from "gatsby"

import React from "react"
import SEO from "../../components/marketing/seo"
import Header from "../../components/marketing/Header/Header.jsx"
import Footer from "../../components/marketing/Footer/Footer.jsx"

import RunwayIcon from '!svg-react-loader!../../../content/assets/icn-runway.svg?name=RunwayIcon'
import ExpensesIcon from '!svg-react-loader!../../../content/assets/icn-expense-tracking.svg?name=ExpensesIcon'
import TaxesIcon from '!svg-react-loader!../../../content/assets/icn-estimated-tax.svg?name=TaxesIcon'

import imgRunwayScreen from '../../../content/assets/img-runway-screen.png'
import imgRunwayDude from '../../../content/assets/img-runway-dude.png'
import MailingListSignup from "../../components/marketing/MailingListSignup/MailingListSignup.jsx"



class Runway extends React.Component {
  render() {
    return (
      <React.Fragment>
        <SEO title={`Hammock - Features - Runway Insights for Freelancers`}
             description={`Do you know how long you will last before you need to find another client? This is one of the hardest things to figure out as a freelancer. Hammock calculates your runway based on your actual income, expenses, taxes, and monthly paycheck.`}
             keywords={['runway for freelancers', 'freelance runway', 'financial forecast for freelancers', 'self-employed', 'selfemployed', 'freelance', 'freelancers', 'freelancing']}
        />

        <Header />

        <div className="feature-page container">
          <div className="row">
            <div className="feature-hero col-lg-6 offset-lg-3 col-md-8 offset-md-2 col-sm-12 col-xs-12">
              <RunwayIcon className="feature-hero-icon" />
              <h1>Runway Insights</h1>
              <p>Hammock calculates your runway based on your monthly paycheck, business expenses, and estimated taxes.</p>
            </div>
          </div>
        </div>

        <div className="taxes-are-hard section grey px-4 px-sm-0">
          <div className="container">
            <div className="row">
              <div className="col-md-5 col-sm-12  pb-5 pb-md-0">
                <h2>What is Runway?</h2>
                <p>Runway is the number of months you can pay your paycheck, business expenses, and estimated taxes without bringing in any additional income.</p>
                <a className="" href="https://app.tryhammock.com/signup" title="Signup for a 14-day FREE Trial">Sign Up for a 14-Day FREE Trial &#10230;</a>
              </div>
              <div className="col-lg-6 offset-lg-1 col-md-6 offset-md-1 col-sm-12">
                <img className="tax-airplane-lady" src={imgRunwayDude} alt="When you know you can pay yourself for the next 6 months, you'll feel more confident taking a much needed vacation." />
              </div>
            </div>
          </div>
        </div>


        <div className="large-feature-image section white px-4 px-sm-0">
          <div className="container">
            <div className="row">
              <div className="col-lg-10 offset-lg-1 col-md-12 col-sm-12 col-xs-12">
                <img className="feature-laptop-screenshot" src={imgRunwayScreen} alt="Using your income and expenses, Hammock will give you an always up-to-date view into your freelance finances." />
              </div>
            </div>
          </div>
        </div>

        <div className="other-features section white px-4 px-sm-0">
          <div className="container">
            <div className="row">
              <div className="our-features-content col-lg-6 offset-lg-3 col-md-12 col-sm-12 col-xs-12">
                <h6 className="section-heading">Other Features</h6>
                <div className="row">
                  <div className="col-6">
                    <Link to="/features/estimated-taxes" className="feature-card">
                      <TaxesIcon className="feature-icon" />
                      <p>Estimated Taxes</p>
                    </Link>
                  </div>
                  <div className="col-6">
                    <Link to="/features/expense-tracking" className="feature-card">
                      <ExpensesIcon className="feature-icon" />
                      <p>Expense Tracking</p>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <MailingListSignup signupLocation="Feature Page: Runway"/>

        <Footer />
      </React.Fragment>
    )
  }
}

export default Runway
