import PropTypes from 'prop-types';
import React from "react"
import './MailingListSignupForm.scss'
import MailchimpSubscribe from "react-mailchimp-subscribe"

const mailchimpUrl = 'https://tryhammock.us3.list-manage.com/subscribe/post?u=056da1137c531e5f01a8fc77f&amp;id=37d6a5d2c8'

const EmailSignupForm = ({ status, message, className, style, onSubmitted, btnWords, signupLocation}) => {
  let input;
  
  const submit = () => {
    window.analytics.track(`NewsletterSignup: ${signupLocation}`, {
      category: 'NewsletterSignup',
      label: signupLocation,
      value: 1
    });

    input && input.value.indexOf("@") > -1 && onSubmitted({EMAIL: input.value});
  }

  return (
    <div className="form-row">
      <div className="col-xs-12 col-sm-8 col-md-9">
        <input ref={node => (input = node)} className="form-control" type="email" placeholder="Your Email"></input>
      </div>
      <div className="col-xs-12 col-sm-4 col-md-3 pt-3 pt-sm-0">
        <button className="btn btn-primary" onClick={submit}>{btnWords}</button>
      </div>
    </div>
  );
};


class MailingListSignupForm extends React.Component {
  static defaultProps = {
    btnWords: 'Subscribe'
  }
  static propTypes = {
    btnWords: PropTypes.string,
    signupLocation: PropTypes.string.isRequired,
  }

  render() {
    return (
      <MailchimpSubscribe
        url={`${mailchimpUrl}&SOURCE=${this.props.signupLocation}`}
        render={({ subscribe, status, message}) => (
          <div className={`submit-form ${status}`}>
            {status === "error" && (
              <div className="alert alert-danger" role="alert">
                <div dangerouslySetInnerHTML={{ __html: message }} />
              </div>
            )}
            {status === "success" && (
              <div className="alert alert-success" role="alert">
                <div dangerouslySetInnerHTML={{ __html: message }} />
              </div>
            )}
            <EmailSignupForm onSubmitted={formData => subscribe(formData)} btnWords={this.props.btnWords} signupLocation={this.props.signupLocation} />
          </div>
        )}
      />
    )
  }
}

export default MailingListSignupForm

